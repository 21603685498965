import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toggleIsAdmin: true,
}

export const toggleState = createSlice({
  name: 'toggleState',
  initialState,
  reducers: {
    toggleIsAdmin: (state, action) => {
      state.toggleIsAdmin = true
    },
  },
})

export const { toggleIsAdmin } = toggleState.actions

export default toggleState.reducer
