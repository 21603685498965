import { configureStore } from '@reduxjs/toolkit'
import toggleStateSlice from './reducers/toggleStateSlice'
import optionListSlice from './reducers/optionListSlice'
import globalVarSlice from './reducers/globalVarSlice'

export const store = configureStore({
  reducer: {
    toggleState: toggleStateSlice,
    optionList: optionListSlice,
    globalVar: globalVarSlice,
  },
  devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
})
