import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productWarranty: {},
}

export const globalVarSlice = createSlice({
  name: 'globalVarSlice',
  initialState,
  reducers: {
    setProductWarranty: (state, action) => {
      state.productWarranty = action.payload
    },
  },
})

export const { setProductWarranty } = globalVarSlice.actions

export default globalVarSlice.reducer
