import { createSlice } from '@reduxjs/toolkit'
import province from '../../stub/province.json'
import carBrand from '../../stub/hangxe.json'

const initialState = {
  provinceList: province,
  carBrand: carBrand,
  distributorList: [],
}

export const optionListSlice = createSlice({
  name: 'optionListSlice',
  initialState,
  reducers: {
    updateProvince: (state, action) => {
      state.provinceList = action.payload
    },
    updateCarBrand: (state, action) => {
      state.carBrand = action.payload
    },
    updateDistributor: (state, action) => {
      state.distributorList = action.payload
    },
  },
})

export const { updateProvince, updateCarBrand, updateDistributor } = optionListSlice.actions

export default optionListSlice.reducer
